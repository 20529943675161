import { getChatWrapper } from "./utils";
declare global {
  interface Window {
    whisbiPosition: number;
    whisbi?: object;
    disableWhisbi?: boolean;
    showWhisbi?: () => void;
  }
}

type WhisbiOrigin = "cdp" | "app";

export const init = (whisbiAccountId: string, origin: WhisbiOrigin = "cdp"): void => {
  const body = document.getElementsByTagName("body")[0];
  const script = document.createElement("script");

  script.type = "text/javascript";
  script.setAttribute("defer", "defer");
  script.src = `//static.whisbi.com/${whisbiAccountId}/connect.js?origin=${origin}&mode=chatbot&lang=SV`;

  body.appendChild(script);
};

// This launches whisbi, the same way it's launched through BlueConic,
// with the only difference being the delay on when it's launched
export const launch = (delay = 2000) => {
  setTimeout(() => {
    if (typeof window.whisbi === "undefined" || Object.keys(window.whisbi).length === 0) {
      if (!window.disableWhisbi && window.showWhisbi) {
        window.showWhisbi();
      }
    }
  }, delay);
};

export const hide = () => {
  // the only official method to hide whisbi did not work
  const el = getChatWrapper();
  if (el) {
    el.style.display = "none";
  }
};

export const show = () => {
  const el = getChatWrapper();
  if (el) {
    el.style.display = "block";
  }
};
